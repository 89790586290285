<template>
	<b-container fluid class="px-0">    
	    <b-row class="justify-content-center mx-0 p-4">
	      <h1 style="text-align: center;">Speechor Support</h1>
	    </b-row>

      <b-row class="justify-content-center mx-0">
      <b-col xs="12" md="8" class="p-2">
        <b-alert v-if="sendingstatus" variant="info"  show>{{sendingstatus}}</b-alert> 
        <b-form v-show="formshow" @submit.prevent="onSubmit">
          <b-form-group
            id="input-group-1"
            label="Full name (required):"
            label-for="input-1">
            <b-form-input
              id="input-1"
              v-model="name"
              type="text"
              placeholder="Enter your name"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-2"
            label="Email address (required):"
            label-for="input-2">
            <b-form-input
              id="input-2"
              v-model="email"
              type="email"
              placeholder="Enter your email"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-3"
            label="Subject (required):"
            label-for="input-3">
            <b-form-input
              id="input-3"
              v-model="subject"
              type="text"
              placeholder="Enter the subject"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label="Message (required):"
            label-for="textarea"
            class="mb-4"
          >
            <b-form-textarea
              id="textarea"
              v-model="message"
              placeholder="Enter your Message"
              class="mb-4"
              required
              rows="7"
            ></b-form-textarea>
          </b-form-group>
          <vue-recaptcha 
          class="my-4" 
          sitekey="6LdeqhwaAAAAAMQZlxMGwcRDjW9ZHWofanDfemF7"
          :loadRecaptchaScript="true"
          @verify="onVerify"
          @expired="onExpired"
          >
          </vue-recaptcha>
          <b-button 
          type="submit" 
          variant="primary"
          :disabled="loading"
          >
          <span v-show="loading" class="spinner-border spinner-border-sm mr-1"></span>
          <span>Send Message</span>
          </b-button>
          
          <b-alert class="my-4" v-if="sendingerror" variant="danger"  show>{{sendingerror}}</b-alert> 
        </b-form>
      </b-col>
      </b-row>

	</b-container> 
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
export default {
 
  data() {
    return {
      name:"",
      email:"",
      subject:"",
      message:"",
      sendingstatus:'',
      sendingerror:'',
      formshow: true,
      loading:false,
      captchaverify:false,
      captchaexpire:false
    };
  },

  methods:{
    onVerify: function (response) {
      this.sendingerror ='';
      if (response !== '') {
        this.captchaverify = true;
        this.captchaexpire = false;
      }
    },

    onExpired: function () {
      this.captchaexpire = true;
    },
     onSubmit(){
      this.sendingerror ='';
      if(this.captchaverify == true && this.captchaexpire == false){
        this.loading = true;
        this.$validator.validateAll().then(isValid => {
          if (!isValid) {
            this.loading = false;
            return;
          }

          if (this.name && this.email && this.subject && this.message) {
            this.$store.dispatch("sendMessage", {name:this.name, email:this.email, subject:this.subject, message:this.message}).then(
              () => {
                this.loading = false;
                this.formshow = false;
                this.sendingstatus = 'Your message was sent! Our support team will be in touch with you ASAP, Thank you!';
              },
              () => {
                this.loading = false;
                this.sendingerror = 'Oops! something went wrong, please try again later!';
              }
            );
          }
        });
      }else{
        this.sendingerror = 'Invalid or expired Captcha!';
      }
     }
  },

  components: { 
    "vue-recaptcha": VueRecaptcha,
  },

  computed: {
  }
};
</script>
<style scoped>


</style>