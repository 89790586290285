import axios from 'axios';

const API_URL = 'https://speechormanager.univerhome.com/api/fth-api/';

class UserService {

  	ordernow(item, ref) {
      return axios.post(API_URL,  {
                          "name":"ordernow",
                          "param":{
                                  "product": item,
                                  "referrer": ref
                          }
                      },
                      { headers: {
                            'Content-Type': 'application/json' 
                          }
                      });
    }

    sendMessage(name, email, subject, message) {
          return axios.post(API_URL,  {
                              "name":"sendmessage",
                              "param":{
                                      "name": name,
                                      "email": email,
                                      "subject": subject,
                                      "message": message
                              }
                          },
                          { headers: {
                                'Content-Type': 'application/json' 
                              }
                          });
        }
    getprice() {
          return axios.post(API_URL,  {
                              "name":"getprice",
                              "param":{}
                          },
                          { headers: {
                                'Content-Type': 'application/json' 
                              }
                          });
    } 
}

export default new UserService();