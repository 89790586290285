<template>
	<b-container fluid class="px-0">    
	    <b-row class="justify-content-center mx-0">
	      <h1 style="text-align: center;">Terms Of Service</h1>
	    </b-row>
	    <b-row class="justify-content-center mx-0">
	      <p style="padding-left: 10%; padding-right: 10%;">
	      	1. Terms:<br>
			By accessing this website, you are agreeing to be bound by these website Terms and Conditions of Use, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this website are protected by applicable copyright and trade mark law.<br><br>

			2. Use License:<br>

			Permission is granted to use the software online, using your credentials, and explore all the software features allowed for your package. This is the grant of a license, not a transfer of title, and under this license you may not:<br>

			- modify or copy the materials;<br>
			- attempt to recompile or reverse engineer any software materials contained on Speechor.com an all subdomains;<br>
			- initiate a dispute or charge-back on your order if you did not provide a proof that the software is not working or not as described;<br>
			- remove any copyright or other proprietary notations from the materials; or
			transfer or attempt to transfer the materials to another person or “mirror” the materials on any other server.<br><br>
			This license shall automatically terminate if you violate any of these restrictions or any other special restrictions determined by us at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.<br><br>

			3. Refund Policy and Support:<br>

			Speechor.com provides 7 days money-back guarantee, In case of using your credits, we will charge you for the used credits, and refund the rest.<br><br>
			
			We provide 24/7 support, if you have any questions, suggestions or need assistance please contact us, our team will get in touch with you.<br><br>

			4. Disclaimer:<br>

			The materials on Speechor.com  are provided “as is”. Speechor.com makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties, including without limitation, implied warranties or conditions of merchant ability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights. Further, Speechor.com does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or on any sites linked to this site.<br><br>

			5. Limitations:<br>

			In no event shall Speechor.com or its suppliers be liable for any damages arising out of the use or inability to use the materials on Speechor.com, even if Speechor.com or a Speechor.com authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.<br><br>

			6. Revisions and Errata:<br>

			The materials appearing on Speechor.com could include technical, typographical, or photographic errors. Speechor.com may make changes to the materials contained on its website at any time without notice. Speechor.com does not, however, make any commitment to update the materials.<br><br>

			7. Purchases and Payment Processing:<br>

			Speechor.com allow individuals to complete transactions securely in a single checkout process. Our order process is conducted by Paypal.<br><br>

			8. Links:<br>

			Speechor.com has not reviewed all of the sites linked to its Internet website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by Speechor.com. Use of any such linked website is at the user’s own risk.<br><br>

			9. Refusal of Service and Business Relations:<br>

			Speechor.com reserves the right to terminate license and cease support in instances of defamation, libel, abuse, or harassment. Speechor.com will terminate all support and relations in instances of software piracy, including but not limited to “nulled”, “cracked”, or otherwise unlicensed versions of Speechor.com scripts, extensions/modules or plugins.<br><br>

			10. Intellectual Property:<br>

			Speechor.com contains material which is owned by or licensed to us. This material includes, but is not limited to trademark, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.<br><br>

			11. Site Terms of Use Modifications:<br>

			Speechor.com may revise these terms of use for its website at any time without notice. By using this website you are agreeing to be bound by the then current version of these Terms and Conditions of Use.<br><br>

			</p>
	    </b-row>
	</b-container> 
</template>

<script>
export default {
 
  data() {
    return {
      visible: false
    };
  },

  methods:{
 
  },

  components: { 
  },

  computed: {
  }
};
</script>
<style scoped>


</style>