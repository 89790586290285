<template>
  <b-container fluid class="px-0">

    <b-row class="justify-content-center mt-4 mb-4" no-gutters> 
        <h1 class="whitetitle">Join Speechor! Signup is FREE<br>4 Powerful AI Modules in 1 Platform</h1>
      </b-row> 
      <b-row class="padd-x" no-gutters>
        <b-col xs="12" md="6" class="p-4 bg-greenb">
          <b-img center height="50" fluid src="https://speechormanager.univerhome.com/uploads/media/3packpng.png" alt="Speechor image"></b-img>
          <h2 class="price-style" style="color: #4b0082;" v-if="getPrice.length>0 && accessWithPayment">${{getPrice[0].price}}</h2>
          <div>

            <b-button v-if="!accessWithPayment" @click.prevent="SignUp" block pill style="background-color: #4b0082;" size="md">
              <span>Sign Up!</span>
            </b-button>
            <p v-if="!accessWithPayment" class="mb-0 mt-2" style="color: #4b0082; text-align: center;">Already have an account?</p>
            <b-button v-if="!accessWithPayment" @click.prevent="SignIn" block pill variant="secondary" size="md" >
              <span>Login/Dashboard</span>
            </b-button>

            <b-button v-if="getPrice.length>0 && accessWithPayment" @click.prevent="ordernow(getPrice[0].id)" block pill style="background-color: #4b0082;" size="md" :disabled="getBtnLoad">
              <span v-show="getBtnLoad" class="spinner-border spinner-border-md mr-2"></span>
              <span>Get Speechor Now!</span> 
            </b-button>
            <b-button v-if="getPrice.length>0 && accessWithPayment" @click.prevent="trynow(getPrice[1].id)" v-show="tryit" block pill variant="secondary" size="md" :disabled="getBtnLoad">
              <span v-show="getBtnLoad" class="spinner-border spinner-border-md mr-2"></span>
              <span>Try Speechor For ${{getPrice[1].price}}!</span>
            </b-button>
          </div>
          <b-list-group class="mt-2 d-flex flex-sm-column flex-md-row">
            <b-list-group-item class="bg-trans-p6 d-flex justify-content-start"><b-icon icon="check-square"  scale="1" variant="dark"></b-icon><p class="pl-2 mb-0 txt-grey1">Compatible with any device</p></b-list-group-item>
            <b-list-group-item class="bg-trans-p6 d-flex justify-content-start"><b-icon icon="check-square"  scale="1" variant="dark"></b-icon><p class="pl-2 mb-0 txt-grey1">Support all operating systems</p></b-list-group-item>
          </b-list-group>
        </b-col>   
        <b-col xs="12" md="6"  class="p-4 bag-grad-1">
          <h2>Speechor Ai Modules</h2>
          <div class="d-block">
            <div class="bg-trans d-flex justify-content-start"><b-icon icon="check" class="iconpostion" scale="3" variant="white"></b-icon><p class="pl-2 mb-0">Text to Speech Module</p></div>
            <div class="bg-trans d-flex justify-content-start"><b-icon icon="check" class="iconpostion" scale="3" variant="white"></b-icon><p class="pl-2 mb-0">AI Image Module</p></div>
            <div class="bg-trans d-flex justify-content-start"><b-icon icon="check" class="iconpostion" scale="3" variant="white"></b-icon><p class="pl-2 mb-0">AI Presentation Module</p></div>
            <div class="bg-trans d-flex justify-content-start"><b-icon icon="check" class="iconpostion" scale="3" variant="white"></b-icon><p class="pl-2 mb-0">AI Text Module</p></div>
            <div class="bg-trans d-flex justify-content-start"><b-icon icon="gift" class="iconpostion" scale="1.5" variant="white"></b-icon><p class="pl-2 mb-0">Free Commercial Rights</p></div>
            <div class="bg-trans d-flex justify-content-start"><b-icon icon="gift" class="iconpostion" scale="1.5" variant="white"></b-icon><p class="pl-2 mb-0">Free Broadcast Rights</p></div>
            <div class="bg-trans d-flex justify-content-start"><b-icon icon="gift" class="iconpostion" scale="1.5" variant="white"></b-icon><p class="pl-2">Affiliate Program Access</p></div>
            <div class="bg-trans d-flex justify-content-start"><b-icon icon="box-arrow-up-right" class="" scale="1.5" variant="primary"></b-icon><a class="pl-2" href="https://discord.gg/GAXh9yMvJb">Join Speechor Discord Server</a></div>
          </div>
        </b-col>
      </b-row>

   

      <b-row class="justify-content-center pt-4 mt-4 mb-4" no-gutters> 
        <h1 class="whitetitle">Speechor Generates AI<br> Voice-overs, Images, Presentations and Text</h1>
      </b-row>
      
      <b-row class="justify-content-center mt-4 mb-4" no-gutters>
        <b-col xs="12" md="6" class="p-4 d-flex justify-content-center">
          <b-card no-body class="overflow-hidden bag-grad-1 text-center">
            <b-card-body>
              <b-card-text>
                <div  style="font-size: 7rem;"><b-icon icon="mic" class="rounded-circle bg-grad-warning p-4" variant="light"></b-icon></div>
                <h4 class="sectitle">Text to Speech Module</h4>
                <p class="whitetitle">Start with pasting the text you want to transform Into Voice-over. Select a Voice, you have 34 languages and 133 voices to choose from.Click Save Speech, in few seconds your voice-over will be generated, you can listen to it or download it into your device</p>
              </b-card-text>
            </b-card-body> 
          </b-card>
        </b-col>
        <b-col xs="12" md="6" class="p-4 d-flex justify-content-center">
          <b-card no-body class="overflow-hidden bag-grad-1 text-center">
          <b-card-body>
                <b-card-text>
                  <div  style="font-size: 7rem;"><b-icon icon="file-earmark-image" class="rounded-circle bg-grad-warning p-4" variant="light"></b-icon></div>
                  <h4 class="sectitle">AI Image Module</h4>
                  <p class="whitetitle">You can generate Ai images from a prompt description or by using another image and create a variation from it. This module is linked to our Discord Speechor Server, which you can join and generate images from it by executing the /imagine command, or just create images from other Speechor server members images with one click.</p>
                </b-card-text>
              </b-card-body>
            </b-card>
        </b-col>
        </b-row>
        <b-row class="justify-content-center mt-4 mb-4" no-gutters>
          <b-col xs="12" md="6" class="p-4 d-flex justify-content-center">
          <b-card no-body class="overflow-hidden bag-grad-1 text-center">
          <b-card-body>
                <b-card-text>
                  <div  style="font-size: 7rem;"><b-icon icon="file-earmark-slides" class="rounded-circle bg-grad-warning p-4" variant="light"></b-icon></div>
                  <h4 class="sectitle">AI Presentation Module</h4>
                  <p class="whitetitle">You need just to Describe your subject, Precise the number of slides, Click generate and download your Ai presentation file, The file is compatible with any slideshow app. This module is linked to our Discord Speechor Server, which you can join and generate presentations from it by executing the /present command, and also you are able to download other Speechor server members presentations.</p>
                </b-card-text>
              </b-card-body>
            </b-card>
        </b-col>
        <b-col xs="12" md="6" class="p-4 d-flex justify-content-center">
          <b-card no-body class="overflow-hidden bag-grad-1 text-center">
            <b-card-body>
                <b-card-text>
                  <div  style="font-size: 7rem;"><b-icon icon="file-earmark-text" class="rounded-circle bg-grad-warning p-4" variant="light"></b-icon></div>
                  <h4 class="sectitle">AI Text Module</h4>
                  <p class="whitetitle">Just like chatGpt Write your description (prompt) with your preferred language, Precise how long you want your output text, in few seconds the text content will be generated as described.</p>
                </b-card-text>
              </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    <b-row class="justify-content-center mt-2 mb-2" no-gutters>
      <b-icon icon="chevron-double-down" variant="warning" font-scale="4" animation="cylon-vertical" aria-hidden="true"></b-icon>
    </b-row>

      

      <b-row class="justify-content-center mt-4 mb-4" no-gutters>
        <h1 class="whitetitle">Speechor Return the Result in 3 Steps Only!</h1>
      </b-row>
  
      <b-row no-gutters>
        <b-col xs="12" md="4" class="p-4 d-flex justify-content-center">
           <b-card
            style="max-width: 25rem;"
            class="mb-4 bag-grad-1 text-center "
            align="center"
          >
            <b-card-text>
              <div  style="font-size: 7rem;"><b-icon icon="card-text" class="rounded-circle bg-grad-primary p-4" variant="light"></b-icon></div>
              <h4 class="sectitle">Step 1</h4>
              <p>Feed Speechor with a reference text (prompt) or an image depending on the module</p>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col xs="12" md="4" class="p-4 d-flex justify-content-center">
          <b-card
            style="max-width: 25rem;"
            class="mb-4 bag-grad-1 text-center"
            align="center"
          >
            <b-card-text>
              <div  style="font-size: 7rem;"><b-icon icon="toggles" class="rounded-circle bg-grad-primary p-4" variant="light"></b-icon></div>
              <h4 class="sectitle">Step 2</h4>
              <p>Select the necessary parameters depending on the mudule you are using</p>
            </b-card-text>
          </b-card>
        </b-col>
      
      <b-col xs="12" md="4" class="p-4 d-flex justify-content-center">
          <b-card
            style="max-width: 25rem;"
            class="mb-4 bag-grad-1 text-center"
            align="center"
          >
            <b-card-text>
              <div  style="font-size: 7rem;"><b-icon icon="arrow-repeat" class="rounded-circle bg-grad-primary p-4" variant="light"></b-icon></div>
              <h4 class="sectitle">Step 3</h4>
              <p>Click Generate, in few seconds the result is ready, voice-over, Image, Article or Presentaion</p>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="justify-content-center mt-2 mb-2" no-gutters>
        <b-icon icon="chevron-double-down" variant="warning" font-scale="4" animation="cylon-vertical" aria-hidden="true"></b-icon>
      </b-row>

      <b-row class="justify-content-center mt-4 mb-4" no-gutters>
        <h1 class="whitetitle">So Easy, Right? <br>"It's time to get yours!"</h1>
      </b-row>

      <b-row class="padd-x" no-gutters>
        <b-col xs="12" md="6" class="p-4 bg-greenb">
          <b-img center height="50" fluid src="https://speechormanager.univerhome.com/uploads/media/3packpng.png" alt="Speechor image"></b-img>
          <h2 v-if="getPrice.length>0 && accessWithPayment" class="price-style" style="color: #800080;">${{getPrice[0].price}}</h2>
          <div>

            <b-button v-if="!accessWithPayment" @click.prevent="SignUp" block pill style="background-color: #800080;" size="md">
              <span>Sign Up!</span>
            </b-button>
            <p v-if="!accessWithPayment" class="mb-0 mt-2" style="color: #800080; text-align: center;">Already have an account?</p>
            <b-button v-if="!accessWithPayment" @click.prevent="SignIn" block pill variant="secondary" size="md" >
              <span>Login/Dashboard</span>
            </b-button>

            <b-button v-if="getPrice.length>0 && accessWithPayment" @click.prevent="ordernow(getPrice[0].id)" block pill style="background-color: #800080;" size="md" :disabled="getBtnLoad">
              <span v-show="getBtnLoad" class="spinner-border spinner-border-md mr-2"></span>
              <span>Get Speechor Now!</span> 
            </b-button>
            <b-button v-if="getPrice.length>0 && accessWithPayment" @click.prevent="trynow(getPrice[1].id)" v-show="tryit" block pill variant="secondary" size="md" :disabled="getBtnLoad">
              <span v-show="getBtnLoad" class="spinner-border spinner-border-md mr-2"></span>
              <span>Try Speechor For ${{getPrice[1].price}}!</span>
            </b-button>
          </div>
          <b-list-group class="mt-2 d-flex flex-sm-column flex-md-row">
            <b-list-group-item class="bg-trans-p6 d-flex justify-content-start"><b-icon icon="check-square"  scale="1" variant="dark"></b-icon><p class="pl-2 mb-0 txt-grey1">Compatible with any device</p></b-list-group-item>
            <b-list-group-item class="bg-trans-p6 d-flex justify-content-start"><b-icon icon="check-square"  scale="1" variant="dark"></b-icon><p class="pl-2 mb-0 txt-grey1">Support all operating systems</p></b-list-group-item>
          </b-list-group>
        </b-col>   
        <b-col xs="12" md="6"  class="p-4 bg-grad-3">
          <h2>Speechor Ai Modules</h2>
          <div class="d-block">
            <div class="bg-trans d-flex justify-content-start"><b-icon icon="check" class="iconpostion" scale="3" variant="white"></b-icon><p class="pl-2 mb-0">Text to Speech Module</p></div>
            <div class="bg-trans d-flex justify-content-start"><b-icon icon="check" class="iconpostion" scale="3" variant="white"></b-icon><p class="pl-2 mb-0">AI Image Module</p></div>
            <div class="bg-trans d-flex justify-content-start"><b-icon icon="check" class="iconpostion" scale="3" variant="white"></b-icon><p class="pl-2 mb-0">AI Presentation Module</p></div>
            <div class="bg-trans d-flex justify-content-start"><b-icon icon="check" class="iconpostion" scale="3" variant="white"></b-icon><p class="pl-2 mb-0">AI Text Module</p></div>
            <div class="bg-trans d-flex justify-content-start"><b-icon icon="gift" class="iconpostion" scale="1.5" variant="white"></b-icon><p class="pl-2 mb-0">Free Commercial Rights</p></div>
            <div class="bg-trans d-flex justify-content-start"><b-icon icon="gift" class="iconpostion" scale="1.5" variant="white"></b-icon><p class="pl-2 mb-0">Free Broadcast Rights</p></div>
            <div class="bg-trans d-flex justify-content-start"><b-icon icon="gift" class="iconpostion" scale="1.5" variant="white"></b-icon><p class="pl-2">Affiliate Program Access</p></div>
            <div class="bg-trans d-flex justify-content-start"><b-icon icon="box-arrow-up-right" class="" scale="1.5" variant="primary"></b-icon><a class="pl-2" href="https://discord.gg/GAXh9yMvJb">Join Speechor Discord Server</a></div>
          </div>
        </b-col>
      </b-row>

      <b-row class="justify-content-center pt-4 mt-4 mb-4" no-gutters>
        <h1 class="whitetitle">Text to Speech Module's Features</h1>
      </b-row>

      <b-row no-gutters>
        <b-col xs="12" md="4"  class="p-4 d-flex justify-content-center">
          <b-card
            style="max-width: 25rem;"
            class="mb-2 bag-grad-1"
          >
            <b-card-text>
              <div  style="font-size: 7rem; text-align: center;"><b-icon icon="person" class="rounded-circle bg-grad-info p-4" variant="light"></b-icon></div>
              <h4 class="sectitle">133 AI Voices</h4>
              <p class="whitetitle">
              Natural and 100% human sounding voices (mp3 file), male and female. Voices are generated by the most advanced AI technologies, to sound native and real depending on the language.</p>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col xs="12" md="4"  class="p-4 d-flex justify-content-center">
          <b-card
            style="max-width: 25rem;"
            class="mb-2 bag-grad-1"
          >
            <b-card-text>
              <div  style="font-size: 7rem; text-align: center;"><b-icon icon="flag" class="rounded-circle bg-grad-info p-4" variant="light"></b-icon></div>
              <h4 class="sectitle">34 Languages</h4>
              <p class="whitetitle">
              The most popular and spoken languages are integrated, Arabic,  Mandarin, Danish, Dutch, English, French,German, Italian, Japanese, Korean, Norwegian, Polish, Portuguese, Spanish…</p>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col xs="12" md="4"  class="p-4 d-flex justify-content-center">
          <b-card
            style="max-width: 25rem;"
            class="mb-2 bag-grad-1"
          >
            <b-card-text>
              <div  style="font-size: 7rem; text-align: center;"><b-icon icon="music-note-list" class="rounded-circle bg-grad-info p-4" variant="light"></b-icon></div>
              <h4 class="sectitle">Musics and Controls</h4>
              <p class="whitetitle">   
              Free downloadable tracks for all moods: happy, sad, funky... You have a nice editing toolbar to control the speed, the pitch of the voice, add pauses or emphasize sentences to change the tone</p>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="justify-content-center mt-2 mb-2" no-gutters>
        <b-icon icon="chevron-double-down" variant="warning" font-scale="4" animation="cylon-vertical" aria-hidden="true"></b-icon>
      </b-row>

      <b-row class="justify-content-center mt-4 mb-4" no-gutters>
        <h1 class="whitetitle">Text to Speech Module's Samples</h1>
      </b-row>
      
        <b-row class="justify-content-center" no-gutters>
          <b-button size="sm" variant="warning" @click.prevent="carousel('pr')">&#171; Back</b-button><div class="px-1 text-warning">{{slideIndex}}/26</div><b-button size="sm"  variant="warning" @click.prevent="carousel('nx')">Next &#187;</b-button>
        </b-row>
        <b-row class="justify-content-center mb-4" no-gutters>
          <b-col xs="12" md="6" class="p-2">
            <div id="slides" class="slidediv">
              <div class="mySlides">
                 <b-row>
                <b-col>
                <b-embed v-b-visible.once="callback" type="video" aspect="16by9" controls>
                  <source v-if="sampleVisible" src="https://speechormanager.univerhome.com/uploads/media/Arabic-ali-1.m4v" type="video/mp4">
                </b-embed>
                </b-col>
              </b-row>
              <b-row >
                <b-col>
                  <h4 class="sectitle">Arabic Male</h4>
                </b-col>
              </b-row>
              </div>
              <div class="mySlides">
                 <b-row>
                <b-col>
                  <b-embed v-b-visible.once="callback" type="video" aspect="16by9" controls>
                  <source v-if="sampleVisible" src="https://speechormanager.univerhome.com/uploads/media/Us-en-austin-1.m4v" type="video/mp4">
                </b-embed>
                </b-col>  
              </b-row>
              <b-row >
                <b-col>
                  <h4 class="sectitle">US English Male</h4>
                </b-col>
              </b-row>
              </div>
              <div class="mySlides">
                 <b-row>
                <b-col>
                <b-embed v-b-visible.once="callback" type="video" aspect="16by9" controls>
                  <source v-if="sampleVisible" src="https://speechormanager.univerhome.com/uploads/media/Uk-en-Michael-1.m4v" type="video/mp4">
                </b-embed>
                </b-col>
              </b-row>
              <b-row >
                <b-col>
                  <h4 class="sectitle">UK English Male</h4>
                </b-col>
              </b-row>
              </div>
              <div class="mySlides">
                 <b-row>
                <b-col> 
                <b-embed v-b-visible.once="callback" type="video" aspect="16by9" controls>
                  <source v-if="sampleVisible" src="https://speechormanager.univerhome.com/uploads/media/Au-en-ryan-1.m4v" type="video/mp4">
                </b-embed>
                </b-col>
              </b-row>
              <b-row >
                <b-col>
                  <h4 class="sectitle">AU English Male</h4>
                </b-col>
              </b-row>
              </div>  
              <div class="mySlides">
                 <b-row>
                <b-col>
                <b-embed v-b-visible.once="callback" type="video" aspect="16by9" controls >
                  <source v-if="sampleVisible" src="https://speechormanager.univerhome.com/uploads/media/Ind-en-destiny-1.m4v" type="video/mp4">
                </b-embed>
                </b-col>
              </b-row>
              <b-row >
                <b-col>
                  <h4 class="sectitle">Indian English Female</h4>
                </b-col>
              </b-row>
              </div>  
              <div class="mySlides">
                 <b-row>
                <b-col>
                  <b-embed v-b-visible.once="callback" type="video" aspect="16by9" controls>
                  <source v-if="sampleVisible" src="https://speechormanager.univerhome.com/uploads/media/Spanish-dylan-1.m4v" type="video/mp4">
                </b-embed>
                </b-col>  
              </b-row>
              <b-row >
                <b-col>
                  <h4 class="sectitle">Spanish Male</h4>
                </b-col>
              </b-row>

              </div>  
              <div class="mySlides">
                 <b-row>
                <b-col>
                <b-embed v-b-visible.once="callback" type="video" aspect="16by9" controls>
                  <source v-if="sampleVisible" src="https://speechormanager.univerhome.com/uploads/media/spanish-us-lupe-1.mp4" type="video/mp4">
                </b-embed>
                </b-col>
              </b-row>
              <b-row >
                <b-col>
                  <h4 class="sectitle">Spanish US Female</h4>
                </b-col>
              </b-row>
              </div>  
              <div class="mySlides">
                 <b-row>
                <b-col> 
                <b-embed v-b-visible.once="callback" type="video" aspect="16by9" controls>
                  <source v-if="sampleVisible" src="https://speechormanager.univerhome.com/uploads/media/Port-bzl-madline-1.m4v" type="video/mp4">
                </b-embed>
                </b-col>
              </b-row>
              <b-row >
                <b-col>
                  <h4 class="sectitle">BR Portuguese Female</h4>
                </b-col>
              </b-row>

              </div>  
              <div class="mySlides">
                <b-row>
                <b-col>
                <b-embed v-b-visible.once="callback" type="video" aspect="16by9" controls>
                  <source v-if="sampleVisible" src="https://speechormanager.univerhome.com/uploads/media/Port-port-isaiah-1.m4v" type="video/mp4">
                </b-embed>
                </b-col>
              </b-row>
              <b-row >
                <b-col>
                  <h4 class="sectitle">PT Portuguese Male</h4>
                </b-col>
              </b-row>
              </div>  
              <div class="mySlides">
                <b-row>
                <b-col>
                  <b-embed v-b-visible.once="callback" type="video" aspect="16by9" controls>
                  <source v-if="sampleVisible" src="https://speechormanager.univerhome.com/uploads/media/Italian-sophia-1.m4v" type="video/mp4">
                </b-embed>
                </b-col>  
              </b-row>
              <b-row >
                <b-col>
                  <h4 class="sectitle">Italian Female</h4>
                </b-col>
              </b-row>
              </div>  
              <div class="mySlides">
                 <b-row>
                <b-col>
                <b-embed v-b-visible.once="callback" type="video" aspect="16by9" controls>
                  <source v-if="sampleVisible" src="https://speechormanager.univerhome.com/uploads/media/Dutch-megan-germany-1.m4v" type="video/mp4">
                </b-embed>
                </b-col>
              </b-row>
              <b-row >
                <b-col>
                  <h4 class="sectitle">German Female</h4>
                </b-col>
              </b-row>
              </div>  
              <div class="mySlides">
                 <b-row>
                <b-col> 
                <b-embed v-b-visible.once="callback" type="video" aspect="16by9" controls>
                  <source v-if="sampleVisible" src="https://speechormanager.univerhome.com/uploads/media/dutch-female-michelle-1.mp4" type="video/mp4">
                </b-embed>
                </b-col>
              </b-row>
              <b-row >
                <b-col>
                  <h4 class="sectitle">Dutch Female</h4>
                </b-col>
              </b-row>
              </div>  
              <div class="mySlides">
                 <b-row>
                <b-col>
                <b-embed v-b-visible.once="callback" type="video" aspect="16by9" controls>
                  <source v-if="sampleVisible" src="https://speechormanager.univerhome.com/uploads/media/Mandarin-nicholas-1.m4v" type="video/mp4">
                </b-embed>
                </b-col>
              </b-row>
              <b-row >
                <b-col>
                  <h4 class="sectitle">CN Mandarin Male</h4>
                </b-col>
              </b-row>
              </div>  
              <div class="mySlides">
                 <b-row>
                <b-col>
                  <b-embed v-b-visible.once="callback" type="video" aspect="16by9" controls>
                  <source v-if="sampleVisible" src="https://speechormanager.univerhome.com/uploads/media/Hindi-cameron-1.m4v" type="video/mp4">
                </b-embed>
                </b-col>  
              </b-row>
              <b-row >
                <b-col>
                  <h4 class="sectitle">Hindi Male</h4>
                </b-col>
              </b-row>
              </div>  
              <div class="mySlides">
                 <b-row>
                <b-col>
                <b-embed v-b-visible.once="callback" type="video" aspect="16by9" controls>
                  <source v-if="sampleVisible" src="https://speechormanager.univerhome.com/uploads/media/Russian-brittany-1.m4v" type="video/mp4">
                </b-embed>
                </b-col>
              </b-row>
              <b-row >
                <b-col>
                  <h4 class="sectitle">Russian Female</h4>
                </b-col>
              </b-row>
              </div>  
              <div class="mySlides">
                 <b-row>
                <b-col> 
                <b-embed v-b-visible.once="callback" type="video" aspect="16by9" controls>
                  <source v-if="sampleVisible" src="https://speechormanager.univerhome.com/uploads/media/Turkish-elijah-1.m4v" type="video/mp4">
                </b-embed>
                </b-col>
              </b-row>
              <b-row >
                <b-col>
                  <h4 class="sectitle">Turkish Male</h4>
                </b-col>
              </b-row>
              </div>  
              <div class="mySlides">
                 <b-row>
                <b-col>
                <b-embed v-b-visible.once="callback" type="video" aspect="16by9" controls>
                  <source v-if="sampleVisible" src="https://speechormanager.univerhome.com/uploads/media/Danish-brianna-1.m4v" type="video/mp4">
                </b-embed>
                </b-col>
              </b-row>
              <b-row >
                <b-col>
                  <h4 class="sectitle">Danish Female</h4>
                </b-col>
              </b-row>
              </div>  
              <div class="mySlides">
                 <b-row>
                <b-col>
                  <b-embed v-b-visible.once="callback" type="video" aspect="16by9" controls>
                  <source v-if="sampleVisible" src="https://speechormanager.univerhome.com/uploads/media/Swedish-jenna-1.m4v" type="video/mp4">
                </b-embed>
                </b-col>  
              </b-row>
              <b-row >
                <b-col>
                  <h4 class="sectitle">Swedish Female</h4>
                </b-col>
              </b-row>
              </div>  
              <div class="mySlides">
                 <b-row>
                <b-col>
                <b-embed v-b-visible.once="callback" type="video" aspect="16by9" controls>
                  <source v-if="sampleVisible" src="https://speechormanager.univerhome.com/uploads/media/Norwegian-aaron-1.m4v" type="video/mp4">
                </b-embed>
                </b-col>
              </b-row>
              <b-row >
                <b-col>
                  <h4 class="sectitle">Norwegian Male</h4>
                </b-col>
              </b-row>
              </div>  
              <div class="mySlides">
                 <b-row>
                <b-col> 
                <b-embed v-b-visible.once="callback" type="video" aspect="16by9" controls>
                  <source v-if="sampleVisible" src="https://speechormanager.univerhome.com/uploads/media/Polish-adam-1.m4v" type="video/mp4">
                </b-embed>
                </b-col>
              </b-row>
              <b-row >
                <b-col>
                  <h4 class="sectitle">Polish Male</h4>
                </b-col>
              </b-row>
              </div>  
              <div class="mySlides">
                 <b-row>
                <b-col>
                <b-embed v-b-visible.once="callback" type="video" aspect="16by9" controls>
                  <source v-if="sampleVisible" src="https://speechormanager.univerhome.com/uploads/media/Finnish-kaitlyn-1.m4v" type="video/mp4">
                </b-embed>
                </b-col>
              </b-row>
              <b-row >
                <b-col>
                  <h4 class="sectitle">Finnish Female</h4>
                </b-col>
              </b-row>
              </div>  
              <div class="mySlides">
                 <b-row>
                <b-col>
                  <b-embed v-b-visible.once="callback" type="video" aspect="16by9" controls>
                  <source v-if="sampleVisible" src="https://speechormanager.univerhome.com/uploads/media/Jose-indonisian-1.m4v" type="video/mp4">
                </b-embed>
                </b-col>  
              </b-row>
              <b-row >
                <b-col>
                  <h4 class="sectitle">Indonesian Male</h4>
                </b-col>
              </b-row>
              </div>  
              <div class="mySlides">
                 <b-row>
                <b-col>
                <b-embed v-b-visible.once="callback" type="video" aspect="16by9" controls>
                  <source v-if="sampleVisible" src="https://speechormanager.univerhome.com/uploads/media/Indonesian-Female-voice-1.m4v" type="video/mp4">
                </b-embed>
                </b-col>
              </b-row>
              <b-row >
                <b-col>
                  <h4 class="sectitle">Indonesian Female</h4>
                </b-col>
              </b-row>
              </div>  
              <div class="mySlides">
                 <b-row>
                <b-col> 
                <b-embed v-b-visible.once="callback" type="video" aspect="16by9" controls>
                  <source v-if="sampleVisible" src="https://speechormanager.univerhome.com/uploads/media/Czech-female-kayla-2-1.mp4" type="video/mp4">
                </b-embed>
                </b-col>
              </b-row>
              <b-row >
                <b-col>
                  <h4 class="sectitle">Czech Female</h4>
                </b-col>
              </b-row>
              </div>  
              <div class="mySlides">
                 <b-row>
                <b-col>
                <b-embed v-b-visible.once="callback" type="video" aspect="16by9" controls>
                  <source v-if="sampleVisible" src="https://speechormanager.univerhome.com/uploads/media/Filipino-Benjamin-1.mp4" type="video/mp4">
                </b-embed>
                </b-col>
              </b-row>
              <b-row >
                <b-col>
                  <h4 class="sectitle">Filipino Male</h4>
                </b-col>
              </b-row>
              </div>  
              <div class="mySlides">
                 <b-row>
                <b-col>
                  <b-embed v-b-visible.once="callback" type="video" aspect="16by9" controls>
                  <source v-if="sampleVisible" src="https://speechormanager.univerhome.com/uploads/media/Freansh-savanah-1.m4v" type="video/mp4">
                </b-embed>
                </b-col>  
              </b-row>
              <b-row >
                <b-col>
                  <h4 class="sectitle">French Female</h4>
                </b-col>
              </b-row>
              </div>  
               
            </div>
          </b-col>
        </b-row>



      <b-row class="justify-content-center mt-2 mb-2" no-gutters>
        <b-icon icon="chevron-double-down" variant="warning" font-scale="4" animation="cylon-vertical" aria-hidden="true"></b-icon>
      </b-row>

      <b-row class="justify-content-center mt-4 mb-2" no-gutters>
        <h1 class="whitetitle">AI Image Module's Features</h1>
      </b-row>

      <b-row no-gutters>
        <b-col xs="12" md="4" class="p-4 d-flex justify-content-center">
           <b-card
            style="max-width: 25rem;"
            class="mb-4 bag-grad-1 text-center "
            align="center"
            img-src="https://speechormanager.univerhome.com/uploads/media/ai33.png"
            img-alt="Image"
            img-top
          >
            <b-card-text>
              <h4 class="sectitle">Text to Image</h4>
              <p>Generate your Ai image from a prompt, for example to generate a painting, add some artists names and known art styles to generate the art piece based on that style. Join our Speechor Discord server to get inspired by others. In Speechor discord server you can generate your own Ai images by calling the /imagine command</p>
            </b-card-text>
           
          </b-card>
        </b-col>
        <b-col xs="12" md="4" class="p-4 d-flex justify-content-center">
          <b-card
            style="max-width: 25rem;"
            class="mb-4 bag-grad-1 text-center"
            align="center"
            img-src="https://speechormanager.univerhome.com/uploads/media/ai22.png"
            img-alt="Image"
            img-top
          >
            <b-card-text>
              <h4 class="sectitle">Image to Image</h4>
              <p> Not only form text, you can also generate your image based on another, just upload your reference image, and let the Ai image module do the rest. In Speechor Discord Server you can recreate from other users images by clicking the options under the generated images.</p>
            </b-card-text>
          </b-card>
        </b-col>
      
      <b-col xs="12" md="4" class="p-4 d-flex justify-content-center">
          <b-card
            style="max-width: 25rem;"
            class="mb-4 bag-grad-1 text-center"
            align="center"
            img-src="https://speechormanager.univerhome.com/uploads/media/ai11.png"
            img-alt="Image"
            img-top
          >
            <b-card-text>
              <h4 class="sectitle">Flexible UI</h4>
              <p>The Ai image UI provide a lot of options, to make it easy to write prompts or copy them from the history table, you can generate variations from other images with one click. Speechor server on Discord provide more additional flexibilty by connecting you to other users creations</p>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="justify-content-center mt-4 mb-2" no-gutters>
        <h1 class="whitetitle">AI Presentation Module's Features</h1>
      </b-row>

      <b-row no-gutters>
        <b-col xs="12" md="4" class="p-4 d-flex justify-content-center">
           <b-card
            style="max-width: 25rem;"
            class="mb-4 bag-grad-1 text-center "
            align="center"
          >
            <b-card-text>
              <div  style="font-size: 7rem;"><b-icon icon="gear" class="rounded-circle bg-grad-primary p-4" variant="light"></b-icon></div>
              <h4 class="sectitle">Easy Setup</h4>
              <p>You can walk through the settings easily, choose a language first, write a clear description of your presentation subject, precise the number of slides, then you're ready to go</p>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col xs="12" md="4" class="p-4 d-flex justify-content-center">
          <b-card
            style="max-width: 25rem;"
            class="mb-4 bag-grad-1 text-center"
            align="center"
          >
            <b-card-text>
              <div  style="font-size: 7rem;"><b-icon icon="file-earmark-slides" class="rounded-circle bg-grad-primary p-4" variant="light"></b-icon></div>
              <h4 class="sectitle">Finished Presentation</h4>
              <p>In up to 5 seconds a key on hand presentation will be generated, and finished by adding a 'Title' and a 'Table of Content' slides at the begining, and a 'Q&A' and 'Thank you' slides at the end</p>
            </b-card-text>
          </b-card>
        </b-col>
      
      <b-col xs="12" md="4" class="p-4 d-flex justify-content-center">
          <b-card
            style="max-width: 25rem;"
            class="mb-4 bag-grad-1 text-center"
            align="center"
          >
            <b-card-text>
              <div  style="font-size: 7rem;"><b-icon icon="folder-check" class="rounded-circle bg-grad-primary p-4" variant="light"></b-icon></div>
              <h4 class="sectitle">Compatible File Format</h4>
              <p>The AI presentation module generates your choosen format PPTX or ODP file, available for download. These formats are compatible with all slideshow apps like Powerpoint or the online apps like Google Slides.</p>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="justify-content-center mt-2 mb-2" no-gutters>
        <b-icon icon="chevron-double-down" variant="warning" font-scale="4" animation="cylon-vertical" aria-hidden="true"></b-icon>
      </b-row>

      <b-row class="justify-content-center mt-4 mb-2" no-gutters>
        <h1 class="whitetitle">AI Presentation Module's Sample</h1>
      </b-row>
      <b-row class="justify-content-center mb-2" no-gutters>
        <p class="whitetitle">We have asked the Module to generate a 3 Slides presentation about<br>
        "Climate change dangers and Solutions to save humanity" <br>
        In up to 5 seconds check the result</p>
      </b-row>

      <b-row class="justify-content-center" no-gutters>
        <b-col xs="12" md="8" class="p-2">
          <b-embed 
          type="video" 
          aspect="4by3" 
          controls 
          autoplay
          >
          <source src="https://speechormanager.univerhome.com/uploads/media/presentation-sample.mp4" type="video/mp4">     
          </b-embed>
        </b-col>
    </b-row>


      <b-row class="justify-content-center mt-2 mb-2" no-gutters>
        <b-icon icon="chevron-double-down" variant="warning" font-scale="4" animation="cylon-vertical" aria-hidden="true"></b-icon>
      </b-row>

      <b-row class="justify-content-center mt-4 mb-2" no-gutters>
        <h1 class="whitetitle">AI Text Module's Features</h1>
      </b-row>

      <b-row class="justify-content-center mb-2" no-gutters>
        <p class="whitetitle">This module is an AI text generator like chatGpt, we designed it to help you generate text content for other modules, <br> like generating text to transform it into voice-over with the Text to Speech module,<br>or replacing/adding content to your generated AI presentation slides</p>
      </b-row>

      <b-row no-gutters>
        <b-col xs="12" md="4" class="p-4 d-flex justify-content-center">
           <b-card
            style="max-width: 25rem;"
            class="mb-4 bag-grad-1 text-center "
            align="center"
          >
            <b-card-text>
              <div  style="font-size: 7rem;"><b-icon icon="input-cursor-text" class="rounded-circle bg-grad-primary p-4" variant="light"></b-icon></div>
              <h4 class="sectitle">Handy Setup</h4>
              <p>All you need to do is writing a short clear prompt describing the text content you want to generate, precise how long you want the output, Click generate</p>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col xs="12" md="4" class="p-4 d-flex justify-content-center">
          <b-card
            style="max-width: 25rem;"
            class="mb-4 bag-grad-1 text-center"
            align="center"
          >
            <b-card-text>
              <div  style="font-size: 7rem;"><b-icon icon="arrow-up-right-circle" class="rounded-circle bg-grad-primary p-4" variant="light"></b-icon></div>
              <h4 class="sectitle">Module linked</h4>
              <p>With one click you can transfer the text generated to the Text to Speech module, to generate a voiceover from it.</p>
            </b-card-text>
          </b-card>
        </b-col>
      
      <b-col xs="12" md="4" class="p-4 d-flex justify-content-center">
          <b-card
            style="max-width: 25rem;"
            class="mb-4 bag-grad-1 text-center"
            align="center"
          >
            <b-card-text>
              <div  style="font-size: 7rem;"><b-icon icon="journal-richtext" class="rounded-circle bg-grad-primary p-4" variant="light"></b-icon></div>
              <h4 class="sectitle">History & Storage</h4>
              <p>Your text generations will be stored in the history, you can reach out all generated texts withing the last 7 days date range.</p>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="justify-content-center mt-2 mb-2" no-gutters>
        <b-icon icon="chevron-double-down" variant="warning" font-scale="4" animation="cylon-vertical" aria-hidden="true"></b-icon>
      </b-row>

      <b-row class="justify-content-center mt-4 mb-4" no-gutters>
        <h1 class="whitetitle">Get Access to our Platform Now<br>
        Don't miss this Offer!</h1>
      </b-row>

      <b-row no-gutters class="justify-content-center bg-img">
        <b-col xs="12" md="8" class="p-4">
          <h2 class="whitetitle mb-4">Let Speechor Boost Your Business<br>4 IN 1 Platform</h2>
          <h1 v-if="getPrice.length>0 && accessWithPayment" class="price-style mb-4" style="color: #FF0080;">${{getPrice[0].price}}</h1>
          <div>

            <b-button v-if="!accessWithPayment" @click.prevent="SignUp" block pill style="background-color: #FF0080;" size="md">
              <span>Sign Up!</span>
            </b-button>
            <p v-if="!accessWithPayment" class="mb-0 mt-2" style="color: #FF0080; text-align: center;">Already have an account?</p>
            <b-button v-if="!accessWithPayment" @click.prevent="SignIn" block pill variant="secondary" size="md" >
              <span>Login/Dashboard</span>
            </b-button>

            <b-button v-if="getPrice.length>0 && accessWithPayment" @click.prevent="ordernow(getPrice[0].id)" block pill style="background-color: #FF0080;" size="lg" :disabled="getBtnLoad">
              <span v-show="getBtnLoad" class="spinner-border spinner-border-lg mr-2"></span>
              <span>Get Speechor Now!</span>
            </b-button>
            <b-button v-if="getPrice.length>0 && accessWithPayment" @click.prevent="trynow(getPrice[1].id)" v-show="tryit" block pill variant="secondary" size="lg" :disabled="getBtnLoad">
              <span v-show="getBtnLoad" class="spinner-border spinner-border-lg mr-2"></span>
              <span>Try Speechor For ${{getPrice[1].price}}!</span>
            </b-button>
          </div>
          <b-list-group class="mt-2 d-flex flex-sm-column flex-md-row">
            <b-list-group-item class="bg-trans-p6 d-flex justify-content-start"><b-icon icon="check-square"  scale="1" variant="light"></b-icon><p class="pl-2 mb-0 txt-grey">Compatible with any device</p></b-list-group-item>
            <b-list-group-item class="bg-trans-p6 d-flex justify-content-start"><b-icon icon="check-square"  scale="1" variant="light"></b-icon><p class="pl-2 mb-0 txt-grey">Support all operating systems</p></b-list-group-item>
          </b-list-group>
        </b-col>   
       
      </b-row>

      <b-row class="justify-content-center mt-4 mb-4" no-gutters>
        <h1 class="whitetitle">Speechor Supported Languages</h1>
      </b-row>

      <b-row class="justify-content-center" no-gutters>
        <b-col xs="12" md="8" class="p-2">
          <b-img center fluid src="../../public/imgl.jpg" alt="Speechor image"></b-img>
        </b-col>
      </b-row>

      <b-row class="justify-content-center mt-2 mb-2" no-gutters>
        <b-icon icon="chevron-double-down" variant="warning" font-scale="4" animation="cylon-vertical" aria-hidden="true"></b-icon>
      </b-row>

      

      <b-row class="justify-content-center pt-4 mt-4 mb-4" no-gutters>
        <h1 class="whitetitle">Speechor is Cheaper and Faster than Hiring</h1>
      </b-row>

      <b-row no-gutters>
        <b-col xs="12" md="6"  class="p-4 d-flex justify-content-center">
          <b-card
            style="max-width: 25rem;"
            class="mb-2 bag-grad-1"
          >
            <b-card-text>
              <div  style="font-size: 7rem; text-align: center;"><b-icon icon="cash-stack" class="rounded-circle bg-grad-danger p-4" variant="light"></b-icon></div>
              <h4 class="sectitle">Cost A lot of money</h4>
              <p class="whitetitle">
              Professionals request a huge amount of money for a short audio recording, or graphic creations, or some few paragraphs of text. With speechor it's doable for cents.</p>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col xs="12" md="6"  class="p-4 d-flex justify-content-center">
          <b-card
            style="max-width: 25rem;"
            class="mb-2 bag-grad-1"
          >
            <b-card-text>
              <div  style="font-size: 7rem; text-align: center;"><b-icon icon="hourglass-split" class="rounded-circle bg-grad-danger p-4" variant="light"></b-icon></div>
              <h4 class="sectitle">Waiting Time</h4>
              <p class="whitetitle">
              They will let you waiting for days before delivering, Why not doing it in few seconds with Speechor</p>
            </b-card-text>
          </b-card>
        </b-col>
       
      </b-row>

      <b-row class="justify-content-center mt-2 mb-2" no-gutters>
        <b-icon icon="chevron-double-down" variant="warning" font-scale="4" animation="cylon-vertical" aria-hidden="true"></b-icon>
      </b-row>

      <b-row class="justify-content-center mt-4 mb-4" no-gutters>
        <h1 class="whitetitle">What are you waiting for?<br>
        Get your Access!</h1>
      </b-row>

      <b-row class="padd-x custom_mb" no-gutters>
        <b-col xs="12" md="6" class="p-4 bg-pupleb">
          <b-img center height="50" fluid src="https://speechormanager.univerhome.com/uploads/media/3packpng.png" alt="Speechor image"></b-img>
          <h2 v-if="getPrice.length>0 && accessWithPayment" class="price-style" style="color: #33006f;">${{getPrice[0].price}}</h2>
          <div>

            <b-button v-if="!accessWithPayment" @click.prevent="SignUp" block pill style="background-color: #33006f;" size="md">
              <span>Sign Up!</span>
            </b-button>
            <p v-if="!accessWithPayment" class="mb-0 mt-2" style="color: #33006f; text-align: center;">Already have an account?</p>
            <b-button v-if="!accessWithPayment" @click.prevent="SignIn" block pill variant="secondary" size="md" >
              <span>Login/Dashboard</span>
            </b-button>

            <b-button v-if="getPrice.length>0 && accessWithPayment" @click.prevent="ordernow(getPrice[0].id)" block pill style="background-color: #33006f;" size="md" :disabled="getBtnLoad">
              <span v-show="getBtnLoad" class="spinner-border spinner-border-md mr-2"></span>
              <span>Get Speechor Now!</span>
            </b-button>
            <b-button v-if="getPrice.length>0 && accessWithPayment" @click.prevent="trynow(getPrice[1].id)" v-show="tryit" block pill variant="secondary" size="md" :disabled="getBtnLoad">
              <span v-show="getBtnLoad" class="spinner-border spinner-border-md mr-2"></span>
              <span>Try Speechor For ${{getPrice[1].price}}!</span>
            </b-button>
          </div>
          <b-list-group class="mt-2 d-flex flex-sm-column flex-md-row">
            <b-list-group-item class="bg-trans-p6 d-flex justify-content-start"><b-icon icon="check-square"  scale="1" variant="light"></b-icon><p class="pl-2 mb-0 txt-grey">Compatible with any device</p></b-list-group-item>
            <b-list-group-item class="bg-trans-p6 d-flex justify-content-start"><b-icon icon="check-square"  scale="1" variant="light"></b-icon><p class="pl-2 mb-0 txt-grey">Support all operating systems</p></b-list-group-item>
          </b-list-group>
        </b-col>   
        <b-col xs="12" md="6"  class="p-4 bg-grad-4">
          <h2>Speechor Ai Modules</h2>
          <div class="d-block">
            <div class="bg-trans d-flex justify-content-start"><b-icon icon="check" class="iconpostion" scale="3" variant="white"></b-icon><p class="pl-2 mb-0">Text to Speech Module</p></div>
            <div class="bg-trans d-flex justify-content-start"><b-icon icon="check" class="iconpostion" scale="3" variant="white"></b-icon><p class="pl-2 mb-0">AI Image Module</p></div>
            <div class="bg-trans d-flex justify-content-start"><b-icon icon="check" class="iconpostion" scale="3" variant="white"></b-icon><p class="pl-2 mb-0">AI Presentation Module</p></div>
            <div class="bg-trans d-flex justify-content-start"><b-icon icon="check" class="iconpostion" scale="3" variant="white"></b-icon><p class="pl-2 mb-0">AI Text Module</p></div>
            <div class="bg-trans d-flex justify-content-start"><b-icon icon="gift" class="iconpostion" scale="1.5" variant="white"></b-icon><p class="pl-2 mb-0">Free Commercial Rights</p></div>
            <div class="bg-trans d-flex justify-content-start"><b-icon icon="gift" class="iconpostion" scale="1.5" variant="white"></b-icon><p class="pl-2 mb-0">Free Broadcast Rights</p></div>
            <div class="bg-trans d-flex justify-content-start"><b-icon icon="gift" class="iconpostion" scale="1.5" variant="white"></b-icon><p class="pl-2">Affiliate Program Access</p></div>
            <div class="bg-trans d-flex justify-content-start"><b-icon icon="box-arrow-up-right" class="" scale="1.5" variant="primary"></b-icon><a class="pl-2" href="https://discord.gg/GAXh9yMvJb">Join Speechor Discord Server</a></div>
          </div>
        </b-col>
      </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
 
  data() {
    return {
      visible: false,
      sampleVisible: false,
      imgvisible: false,
      imgVisible: false,
      tryit:true,
      slideIndex : 0,
      referrer : '',
      accessWithPayment:false,
    };
  },
  mounted() {
      //this.$store.dispatch("getprice");
      this.carousel('nx');
      this.referrer = this.$route.query.r;
     
      if(this.referrer !== undefined && this.referrer !== '' && this.referrer !== null) {
        //set cookie
        this.setCookie();
      }
     
  },
  methods:{
    callback(visible) {
      if (visible) {
        this.sampleVisible = true;
      }
    },

    SignIn(){
      window.location.replace('https://speechorapp.univerhome.com/login');
    },

    SignUp(){
      window.location.replace('https://speechorapp.univerhome.com/Signup?r='+this.getCookie("referid_spchr"));
    },

    imgcallback(imgvisible) {
      if (imgvisible) {
        this.imgVisible = true;
      } 
    },

    ordernow(id){
      const affCookie = this.getCookie("referid_spchr");
      this.$analytics.fbq.event('getSpeechorBtn');
      this.$store.dispatch("ordernow", {product: id, referrer: affCookie});   
      },
    trynow(id){
        const affCookie = this.getCookie("referid_spchr");
        this.$analytics.fbq.event('getSpeechorBtn');
        this.$store.dispatch("ordernow", {product: id, referrer: affCookie});   
      },
    
    carousel(d) {
      var i;
      var x = document.getElementsByClassName("mySlides");
      for (i = 0; i < x.length; i++) {
        x[i].style.display = "none"; 
      }
      
      if (d == 'nx' && this.slideIndex < x.length) {this.slideIndex++;}
      if (d == 'pr' && this.slideIndex > 1) {this.slideIndex--;}
      x[this.slideIndex-1].style.display = "block";  
    },

    setCookie() {
      const refcookie = this.getCookie("referid_spchr");
      //set expire time
      const d = new Date();
      d.setTime(d.getTime() + (14 * 24 * 60 * 60 * 1000));
      let expires = "expires="+d.toUTCString();

      if(refcookie == ""){ 
        document.cookie = "referid_spchr="+ this.referrer +";" + expires + ";path=/;SameSite=None;secure";
      }
      
    },

    getCookie(cname) {
        let name = cname + "=";
        let ca = document.cookie.split(';');
        for(let i = 0; i < ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {

            const fc = c.substring(name.length, c.length);
            return fc;
          }
        }
        return "";
    },

  },

  components: { 
  },

  computed: {
    ...mapGetters(["getPrice", "getBtnLoad"])
  }
};
</script>
<style scoped>
  .mySlides{
    padding-left: 10px;
    padding-right: 10px;
  }
  @media not screen and (max-width: 480px) {
     .mySlides{
      padding-left: 50px;
      padding-right: 50px;
    }

  }
 .border-left{
  background: #482b69;
  border: none;
 }
 .sectitle{
  color: #ffba00;
  text-align: center;
 }
 
 .whitetitle{
  color: #fff;
  text-align: center;
 }
 .price-style{
  text-align: center;
  font-family: 'almendra display',Sans-serif;
  font-weight: 700; 
}
.bg-trans{
  background: transparent;
  border: none;
  padding: 10px 20px;
}
.bg-trans-p6{
  background: transparent;
  border: none;
  padding: 5px 20px;
}
.txt-grey{
  color: white;
  font-size: 12px;
}
.txt-grey1{
  color: grey;
  font-size: 12px;
}
.iconpostion{
  position: relative;
  top: 5px;
  margin-right: 10px;
}
.bg-orge{
  background-color: #ff9400;
}
.bg-blue1{
  background-color: #007aff;
}
.bg-puplea{
  background-color: #6f42c1;
}
.bg-pupleb{
  background-color: #c7a2fc;
}
.bg-greenb{
background-color: #fff;
}
.bg-img{
  background-color: transparent;
  background-image: linear-gradient(140deg,#1A1060 17%,#f2295b 100%);
}

.bg-grad-info{
  background: rgb(0,102,216);
  background: linear-gradient(133deg, rgba(0,102,216,1) 41%, rgba(8,71,133,1) 55%);
}
.bg-grad-primary{
  background: rgb(21,50,255);
  background: linear-gradient(133deg, rgba(21,50,255,1) 41%, rgba(8,17,133,1) 55%);
}
.bg-grad-warning{
  background: rgb(236,177,0);
  background: linear-gradient(133deg, rgba(236,177,0,1) 41%, rgba(213,121,0,1) 55%);
}
.bg-grad-danger{
  background: rgb(249,6,6);
  background: linear-gradient(133deg, rgba(249,6,6,1) 41%, rgba(158,0,0,1) 55%);
}
.bag-grad-1{
  background: rgb(110,0,183);
  background: linear-gradient(133deg, rgba(75, 0, 130, 1)41%, rgba(114, 14, 158, 1) 55%);
}
.bg-grad-2{
  background: rgb(110,0,183);
  background: linear-gradient(133deg, rgba(114, 14, 158, 1)41%, rgba(128, 0, 128, 1)55%);
}
.bg-grad-3{
  background: rgb(110,0,183);
  background: linear-gradient(133deg, rgba(69, 44, 99, 1)41%, rgba(128, 0, 128, 1)55%);
}
.bg-grad-4{
  background: rgb(110,0,183);
  background: linear-gradient(133deg, rgba(69, 44, 99, 1)41%, rgba(51, 0, 111, 1)55%);
}

.custom_mb {
  margin-bottom: 50px;
}

@media not screen and (max-width: 767px) {
  .padd-x{
    padding-right: 10%;
    padding-left: 10%;
  }
}

</style>