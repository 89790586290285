import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
var ls = new SecureLS({encodingType: 'rc4', isCompression: false});
ls.removeAll();
import UserService from '../services/user.service';

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    something:true,
    offers:[],
    showHeader:false,
    loading:false
  },
  plugins: [
    
    createPersistedState({
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key)
      }
    })
  ],
  mutations: {
    SET_SOMETHING(state, something){
      state.something = something;
    },
    SET_PRICE(state, offers){
      state.offers = offers;
    },
  
    SET_BTN_LOAD(state, loading){
      state.loading = loading;
    }
  },

  actions: {

    async initialize({commit}){
      commit("SET_SOMETHING", false);
    },


    async getprice({ commit}){
      commit("SET_SOMETHING", true);
      const priceJson = await UserService.getprice()
                    .then(response => {
                    if (response.data!=='' && response.data.constructor === Object){
                      var reStatus = response.data.response.status;
                        if(reStatus == '300' || reStatus == '301' || reStatus == '302' || reStatus == '114'){
                          return '';
                        } else {
                          return response.data.response.result;
                        }
                    } else {
                      return [];
                    }
                    });
      commit("SET_PRICE", priceJson);
      
    },

    async ordernow({commit},{product, referrer}){
          commit("SET_SOMETHING", true);
          commit("SET_BTN_LOAD", true);
          if (product!=='' && product!==null && product!==undefined){
            const appUrl = await UserService.ordernow(product, referrer)
                    .then(response => {
                    if (response.data!=='' && response.data.constructor === Object){
                        var reStatus = response.data.response.status;
                        if(reStatus == '300' || reStatus == '301' || reStatus == '302'){
                          commit("SET_BTN_LOAD", false);
                          return '';
                        } 
                        else if (reStatus == '111' || reStatus == '104') {
                          commit("SET_BTN_LOAD", false);
                          return '';
                        }
                        else {
                          return response.data.response.result;
                        }
                    } else {
                      commit("SET_BTN_LOAD", false);
                      return '';
                    }
                    });
            if (appUrl !== ''){  
              window.location.replace(appUrl); 
            }
          
          } else {
            commit("SET_BTN_LOAD", false);
          }
                    
    },
    
    async sendMessage({commit}, {name, email, subject, message}){
          commit("SET_SOMETHING", false);
          await UserService.sendMessage(name, email, subject, message)
                    .then(response => {
                    if (response.data!=='' && response.data.constructor === Object){
                        var reStatus = response.data.response.status;
                        if(reStatus == '200'){
                          return Promise.resolve(response);
                        }
                        else {
                          return Promise.reject(response);
                        }
                    } else {
                      return Promise.reject(response);
                      }
                    });       
                 
    },
  },
  
  getters: {
    getPrice(state){
      return state.offers;
    },
  
    getBtnLoad(state){
      return state.loading;
    },
    
  }
  
});