<template>
    <footer id="footer-stl" class="bg-light">
      <b-navbar type="dark" class="nav-stl" > 
        <b-navbar-nav class="txt-foot-b ml-auto mr-auto">
          <b-nav-item  to="/">Home</b-nav-item>
          <b-nav-item to="/Askus">Support</b-nav-item>
          <b-nav-item href="https://discord.gg/GAXh9yMvJb">Discord</b-nav-item>
        </b-navbar-nav>
      </b-navbar>
      <b-navbar type="dark" class="nav-stl" >
        <b-navbar-nav class="txt-foot-b ml-auto mr-auto">
          <b-nav-item to="/Privacypolicy">Privacy</b-nav-item>
          <b-nav-item to="/Cookies">Cookies</b-nav-item>
          <b-nav-item to="/Terms">Terms</b-nav-item>
        </b-navbar-nav>
      </b-navbar>
    <p class="mb-0 p-1 text-secondary text-center txt-foot-b"> &copy; <span>{{year}}</span> by Speechor</p>
    </footer>
</template>

<script>
export default {
  methods: {
  },
  data() {
    return {
      year:'2024',
    };
  },
  mounted(){
    this.year = new Date().getFullYear();
  },
  computed: {
  
  }
};
</script>

<style scoped>
  #footer-stl{ 
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
  }
  .nav-stl{
    background-color:#31004b!important;
  }

  .txt-foot-b{
  font-size: 0.8em;
  margin-top: 0.1em;
  margin-bottom: 0.1em;
  margin-left: 0;
  margin-right: 0;
  font-weight: 100;

  }


</style>