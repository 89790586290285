<template>
  <div id="app">
    <scrol-top>
      <a class="btn btncss btn-light">
        <b-icon icon="chevron-up" scale="1.5" variant="light"></b-icon>
      </a>
    </scrol-top>
    <div id="nav">
    <top-header></top-header>
    </div>
    <router-view />
    
    <app-footer></app-footer>
    
  </div>
</template>
<script>
import TopHeader from "@/components/TopHeader.vue";
import ScrollTop from "@/components/ScrollTop.vue";
import Footer from "@/components/Footer.vue";

export default {
  data() {
    return {
    };
  },
  components: { 
    "top-header": TopHeader,
    'app-footer': Footer,
    'scrol-top': ScrollTop
  },
  computed: {
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');
#app {
  font-family: /*"Montserrat", Avenir,*/ Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  /*
  background: rgb(2,0,36);
  background: linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(10,10,113,1) 61%, rgba(0,18,170,1) 85%);*/
  
  background: rgb(110,0,183);
  background: linear-gradient(180deg, rgba(75, 0, 130, 1)0%, rgba(114, 14, 158, 1) 20%, rgba(128, 0, 128, 1)40%,rgba(69, 44, 99, 1)60%,rgba(51, 0, 111, 1)80%);

  color: white;
  /*font-size: 0.75rem;*/
  position: relative;
  padding-bottom: 150px;
  min-height: 100vh;
  display: block;
}

.btncss {
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.55);
    font-size: 18px;
    padding: auto;
    pointer-events: none;
}

</style>
