<template>
	<b-container fluid class="px-0">
      <b-row class="justify-content-center mx-auto my-4">
        <b-icon icon="check-circle" scale="5" variant="success"></b-icon>
      </b-row>  
	    <b-row class="justify-content-center mx-0 mt-4">
    	   <h4 style="text-align: center;padding-left: 10%; padding-right: 10%;padding-top: 20px;">Payment Successfully processed! your order is processing now, we will send you an email with your
          Username and Password ASAP.
          It will take up to 1h to prepare your access.</h4>
	    </b-row>
      <b-row class="justify-content-center mx-0">
        <h1 style="text-align: center;padding-left: 10%; padding-right: 10%;">Thank You!</h1>
      </b-row>
	</b-container> 
</template>

<script>
export default {
 
  data() {
    return {
      visible: false
    };
  },

  mounted(){
    this.$analytics.fbq.event('SuccessPayment', {currency: "USD", value: 29.00});
    this.$gtag.event('conversion', {
        'send_to': 'AW-710213325/ZJq_CLfs4_sCEM3909IC',
        'value': 47,
        'currency': 'USD',
        'transaction_id': ''
    });
  }
};
</script>
<style scoped>


</style>